.info-box
  box-shadow 0 4px 6px rgba(0, 0, 0, 0.2)
  padding 20px
  border-radius 2px
  max-width 400px
  @media screen and (min-width:960px)
    margin-left:40px;
    margin-bottom: 45px;
    margin-top: 80px;
  h3, p
    text-align left

  .info-video
    width: 100px;

.info-video-container
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  overflow hidden
  iframe
    position: absolute;
    width: 100%;
    height: 100%;
  div
    position absolute
    width 100%
    height 100%
    .image-container
      .thumbnail
        width:100%
        height: 100%
      .playbutton
        position absolute
        width 10%
        top 0
        left 0
        right 0
        bottom 0
        margin auto
        cursor pointer
    p
      position absolute
      margin auto
      text-align center
      color #666
      font-weight 300
      bottom 0
      font-size 0.75rem
      left 0
      right 0
      background-color rgba(0,0,0,.05)
      @media screen and (max-width:464px)
        font-size 0.5rem
        bottom 2.5px

