@require './mixins'

md-dialog[role='alertdialog'] {
  width 500px
  max-width 80vw
}

@keyframes hide-announcement
  0%
    max-height 100vh
  90%
    transform scaleY(0) translateY(-100%)
    max-height 0vh
  100%
    padding 0
    margin 0 auto
    max-height 0vh
    transform scaleY(0) translateY(-100%)

#journals-left .journal
  .cover-stack
    transition transform .5s linear 0s
    transform-origin bottom left
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    margin 0 auto
    &:nth-child(1)
      transform translate(-2px, -2px)
    &:nth-child(3)
      position relative
      transform translate(2px, 2px)
  &:hover .cover-stack
    transition-delay 1s
    &:nth-child(1)
      transform translate(-5px, -5px) rotate(-5deg)
    &:nth-child(2)
      transform rotate(-1deg)
    &:nth-child(3)
      transform translate(5px, 5px) rotate(3deg)


.announcement
  background-color white
  font-size 90%
  box-shadow 0px 2px 4px 0px rgba(0,0,0,0.15)
  margin 20px auto
  padding 10px
  border-radius 2px
  box-sizing border-box
  transform scaleY(1)
  transform-origin top
  display block
  overflow hidden
  //transition transform .5s ease-in-out, max-height .5s ease-in-out, display .5s linear 2s
  clearfix()
  .announcement-content
    overflow hidden
  &.inactive .announcement-img, &.inactive .announcement-content
    //display none !important
    animation-name hide-announcement
    animation-duration .5s
    animation-fill-mode forwards
  &.inactive .announcement-title
    margin-bottom 0
    padding-bottom 0
  .close-button
    float right
    margin 0
  .announcement-content *:first-child
    margin-top 0
  .announcement-title
    //font-size 120%
    font-weight normal
    //margin 0
    margin-top 5px
  .announcement-img
    float left
    margin-right 20px
    max-width 50%
  .announcement-date
    color $gray
    margin 0
    //font-size 70%
    padding 5px
    margin-top 5px
    float right
.announcement-container-mobile .announcement

  margin 5px auto
  width calc(100% - 20px)
  padding 10px
  .announcement-img
    float none
    display block
    margin 5px auto
    max-width 90%
  .announcement-title
    font-size 100%
    margin-top 10px

.announcements-loading
  height 100px
  width 100%
  text-align center
  md-progress-circular
    padding 20px
    margin 0 auto

.catalog-mobile
  background-color #eee
  padding 10px

.new
  position relative
  overflow hidden
.new::after
  content 'NEW'
  display block
  position absolute
  font-size 11px
  padding 2px
  width 100px
  text-align center
  top 5px
  right -30px
  transform rotate(35deg)
  background-color $orange
  color white
.new:lang(de)::after
  content: 'NEU'
.new:lang(fr)::after
  content 'NOUVEAU'
  top 10px
  right -25px

.ims-grid
  display flex
  flex-wrap wrap
  margin -5px
  width calc(100% + 10px)
  for num in (1..10)
    &.ims-grid-gutter-{(num*10)}
      margin -(num*5)px
      width "calc(100% + %spx)" % (num*10)
      .ims-grid-tile
        padding (num*5)px
    @media screen and (max-width: 3050px) and (min-width: 2801px)
      &.ims-grid-gutter-{(num*10)}
        margin -(num*10)px
        width "calc(100% + %spx)" % (num*20)
        .ims-grid-tile
          padding (num*10)px
    @media screen and (min-width: 3051px)
      &.ims-grid-gutter-{(num*10)}
        margin -(num*20)px
        width "calc(100% + %spx)" % (num*40)
        .ims-grid-tile
          padding (num*20)px
  .ims-grid-tile
    flex-grow 0
    flex-basis 25%
    max-width 25% // fix for IE11
    @media screen and (max-width: 1000px)
      flex-basis 50%
      max-width 50% // fix for IE11
    box-sizing border-box
    padding 5px
  &.ims-grid-smaller .ims-grid-tile
    flex-basis 20%
    max-width 20% // fix for IE11
    @media screen and (max-width: 1000px)
      flex-basis 33.333%
      max-width 33.333% // fix for IE11
  &.ims-grid-larger .ims-grid-tile
    flex-basis 33%
    max-width 33% // fix for IE11
    @media screen and (max-width: 1000px)
      flex-basis 50%
      max-width 50% // fix for IE11


.offers
  h2
    font-family 'Open Sans', sans-serif
    font-weight normal
    > *
      vertical-align middle
  .cover
    max-width 100%
    max-height 100%
  .detail
    .title
      height 27px
      line-height 25px
      width 100%
      span
        font-weight bold
        font-size .8em
        color #003b66
        line-height .8em
        display inline-block
        vertical-align middle
    .detailline
      color #555
      font-size .7em
      line-height 14px
      width 100%
  .cover-wrapper
    position relative
    .offer-duration
      background-color $orange
      color $white
      text-align center
      position absolute
      bottom 0
      left 0
      right 0
      pointer-events none

.catalog-mobile
  .catalog.titles h2, .offers h2
    text-align center
    padding-top 0

#journals-right expand-collapse
  .trigger.float-right
    float right
    margin 0
  .trigger ng-md-icon>svg
    transition transform .5s ease-in-out
  &.collapsed .trigger ng-md-icon[icon=expand_less]>svg
    transform rotate(180deg)
  .content
    transform scaleY(1)
    transform-origin top
    transition transform .5s ease-in-out, max-height .5s ease-in-out
    max-height 10000vh
    overflow hidden
    &.hidden
      transform scaleY(0)
      max-height 0


.catalog.titles
  width 100%
  div.catalog-filter
  .catalog-filter
    md-input-container
      margin 0 5px 0 0
      ng-md-icon
        display inline-block;
        transform translateY(3px);
        svg
          height 16px
          width 16px
  h2
    text-align left
    font-weight normal
    font-size 1.5em
    padding-top 0
    margin-top 20px
    margin-bottom 20px
    margin-right 0
    > *
      vertical-align middle
    a.catalog-filter-additionalinfo
      display inline-block
      font-size 10pt
      font-family Roboto, "Helvetica Neue", sans-serif
      line-height 12pt
      color $gray
      text-decoration underline
      font-weight normal
      cursor pointer
    .md-errors-spacer
      display none
    md-select.round:not([disabled])
      .md-select-value
        border-radius 20px
        background white
        color rgb(0,103,178)
        //border 1px solid rgb(0,103,178)
      &:focus .md-select-value
        background rgb(0,103,178)
        color white
  .titles-list
    .journal
      opacity .6
      transition opacity .5s ease-in-out
    .journal:hover
      opacity 1
    .detail
      .title
        height 27px
        line-height 25px
        width 100%
        span
          font-weight bold
          font-size .8em
          color #003b66
          line-height .8em
          display inline-block
          vertical-align middle


#journals-left, #journals-right
  transition: max-width 0.5s

@media screen and (max-width: 960px)
  #journals-left
    max-width: 25%

  #journals-right:not(.flex-100)
    max-width: 75%

#journals-left
  background-color $white
  position: relative

  .cover-wrapper
    width calc(100% - 50px)
    height calc(100% - 50px)
    // margin 25px
    margin 25px 25px 0 25px

  .cover-container
    max-width 416px
    // max-height 312px
    width 100%
    height auto
    text-align center

    &.title
      height auto
      padding-top 5px
  img
    width auto
    height auto
    max-width 100%
    max-height 100%

coverTitleLabel()
  color #fff
  // background-color rgba(0,0,0,0.8)
  background rgba(40,40,40,0.8) /* Old browsers */
  background -moz-radial-gradient(center, ellipse cover, rgba(40,40,40,0.8) 0%, rgba(0,0,0,0.6) 91%) /* FF3.6-15 */
  background -webkit-radial-gradient(center, ellipse cover, rgba(40,40,40,0.8) 0%,rgba(0,0,0,0.6) 91%) /* Chrome10-25,Safari5.1-6 */
  // background radial-gradient(ellipse at center, rgba(40,40,40,0.8) 0%,rgba(0,0,0,0.6) 91%) /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  // filter progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e1e1e', endColorstr='rgba(0,0,0,0.8)',GradientType=1 ) /* IE6-9 fallback on horizontal gradient */

journalsLeft()
  #journals-left
    .journal-grid .tile
      &:not(:nth-child(n+3)) .cover-container
        margin-bottom -25px
      figure
        cursor pointer
        overflow hidden
      // padding-top 50px
      .cover-container
        position relative
        width auto
        clearfix()
        // margin-top 15%
        img
          position relative
          display inline-block
        &.title
          coverTitleLabel()
          padding 10px 10px 15px
          font-weight bold
          width calc(100% - 20px)
          position relative
          bottom 47.6px
          opacity 0
          transition none
      &:hover .cover-container.title
        transition opacity 300ms ease-in
        opacity 1
      // remove this child selectors to show only on hover
      &:nth-child(1) .cover-container.title, &:nth-child(2) .cover-container.title
        transition opacity 300ms ease-in
        opacity 1

@media screen and (min-width: 1279px)
  journalsLeft()
  // FIREFOX ONLY // first 2 tiles (= first row) => title
  @css
    @-moz-document url-prefix()
      #journals-left .journal-grid .tile:not(:nth-child(n+3)) .cover-container.title
        bottom: 58px !important;

#newsfeed-toolbar
  color $black

.issue_details_view {
  position: relative;

  .close-button {
    position: absolute;
    top 8px;
    right 0;
  }
}

.journal-grid {
  position: absolute;
}

.journal-detail-view-top > div {
  vertical-align: top;
  margin: 0 9px;
}

#journals-left {
  .journal-grid {
    .titleLine {
      padding-top: 10px;
    }
  }
}

div.progress-fullscreen
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  z-index 200
  background-color rgba(255,255,255,.9)

div.progress-fullscreen md-progress-circular, md-progress-circular.fullscreen
  position absolute
  top 50%
  left 50%
  transform translate(-50%, -50%)
  z-index 99999
  svg path
    stroke-width 5px !important


// journal loading animation
md-progress-circular
  padding 40% 0 40% 0
  display block
  background-image url(assets/images/signet.svg)
  background-position center center
  background-repeat no-repeat
  background-size 50%
  svg
    position relative
  &:not([md-diameter]) svg path
    stroke-width 3px !important

// journal
.issue_details_view_loading
  transition opacity 600ms ease-out
  opacity 0

.issue_details_view_ready
  transition opacity 600ms ease-out
  opacity 1

.issue_details_view_hide
  transition none

#issue_details_view
  .md-headline
    padding-top: 10px;
  .description
    padding: 10px 0;

#related_issues_view
  .loadMoreButton
    margin-top 16px

#journals-left
  .grid-list-container
    display: block;
    position: relative;
    //position: absolute;
    //top: 0;
    //left: 0;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    margin: 20px;
    h2
      font-weight normal
      margin-top 0

#journals-right
  .related-issues-tile
    img
      margin-bottom: 48px;
    md-grid-tile-footer
      background: transparent;
      color: #000;
      font-size: 0.75em;
      text-align: center;
      align-items: flex-start;
      figcaption, .title, .date
        text-align: center;
        width: 100%;

#journals-right
  position relative
  overflow hidden
  #issue_details
    z-index 10
    //height calc(100% - 64px) // not working on Apple Safari
    //margin-bottom 64px
    max-height 100%
    overflow-y scroll
    &.singleissue
      height: 100%
      max-height: 100%
      position: absolute
      width: 50%
      overflow-y: auto
    .journal-detail-view-top
      .journal-detail-right
        max-height 267px
        align-items flex-start
        .md-button.md-raised:not([disabled])
          box-shadow none
        .md-button.md-primary.md-raised:not([disabled]):hover
          opacity 0.75
        div
          width 100%
          &.description
            overflow auto
      img
        width 100%
        height auto
    // md-tabs-content-wrapper
    //   overflow-y scroll
  #journal-tabs
    // width 100%
    width calc(100%)
    display block
    position relative
    z-index 15
    bottom 0
    overflow-y scroll
    padding 0 20px
    box-sizing border-box
    &.singleissue
      width 50%
      right 0
    md-toolbar
      transition width 300ms ease-in
    &.inactive
      overflow-y hidden
      -webkit-transform translateX(100%)
      -moz-transform translateX(100%)
      -ms-transform translateX(100%)
      -o-transform translateX(100%)
      transform translateX(100%)
      md-toolbar
        width 100%
        display block
    md-input-container
      font-size 0.8rem
    .journal_feed_filter
      max-width 50%
      color $black
    md-content
      height auto
      overflow hidden
      background rgb(255,255,255)
    md-list-item
      padding 20px 0
      h3
        margin 5px 0
      img
        border-radius 0
        margin 5px 15px 5px 5px
  md-progress-circular
    margin 0 auto

#newsfeeds-mobile
  padding 16px

  md-input-container
    margin 0 0 8px 0
    align-self flex-end

  md-list
    padding 0

    md-list-item
      padding 16px 0

      .newsfeed-image
        height 90px
        width 90px
        margin 0 16px 16px 0
        background-size cover
        background-position center center

      .newsfeed-headline
        width calc(100% - 106px)

        span
          font-size 10px

        h3
          margin 8px 0
          font-size 14px
          line-height 18px
          font-weight 400

      .newsfeed-text
        font-size 12px

// ISSUE DETAILS transition
.issue_details_view
  &.transIsLoading
    transition opacity 600ms ease-out
    opacity 0
  &.transIsReady
    transition opacity 600ms ease-out
    opacity 1
  &.transIsHiding
    transition none


#journals-right
  #journal-tabs
    position: absolute
    transition transform 1s ease
    background-color #eee

  .feedText
    width 100%

  md-grid-tile-footer
    bottom -5px

#journals-right
  .journal-grid,
  .journal-grid-right
    img
      width 100%
      height auto
      display block
    .footer
      background-color rgba(0,0,0,0.5)
      coverTitleLabel()

/*
#journals-left
  .journal-grid
    img
      max-width 100%
      max-height 100%
      height auto
      width auto
  .ng-scope
    width 100%
    height 100%
    max-height 400px
    max-width 300px
*/

#journal-tabs
  md-tabs-content-wrapper
    md-tab-content > div:first-child
      height 100%

.searchlist
  .cover
    height 100px
    width auto
    margin-right 20px
    margin-bottom 25px

#journals-mobile
  min-height calc(100% + 1px)
  padding 16px 16px 4px 16px
  overflow-y scroll
  -webkit-overflow-scrolling touch

  div
    margin 8px 0

    .title
      margin-top 4px
      font-weight bold

#selected-issue-headline-mobile /* prevents overlapping with close button */
  margin-right 32px

#journals-left

  .journal
    text-align center

  .title
    text-align center
    font-weight bold

.feed-cover-container {
  min-width 170px

  div {
    width: 150px;
    height 150px;
    overflow hidden;
    text-align center;
    margin-right: 20px;

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    img {
      width: auto;
      height 100%;
      margin-left: -25% !important;
    }
  }
}

.feed-details-container {
  .feedText {
    margin-top: 5px;

    h3 {
      margin-bottom: 0;
    }

    & > p {
      margin: 0;
    }
  }
}


/* BUGFIXXES */
// FIREFOX
// IE
#journal-tabs
  .layout-row
    width 100%
    .feed-details-container
      width 100%


/* MOBILE */
#issue-details-mobile
  height 100%
  width 100%
  background-color $white
  position absolute
  top 0
  z-index 10

  .issue-details
    padding 16px
    transition opacity 600ms ease-out

    &.transIsLoading
      opacity 0
    &.transIsReady
      opacity 1
    &.transIsHiding
      transition none

    .issue-cover
      img
        height auto
        width 100%

    .issue-text
      padding-left 16px

      .md-subhead
        font-size 80%

      .issue-read
        margin 8px 0
        padding 0

    .close
      position absolute
      top 8px
      right 8px
      margin 0
      fill rgba(0,0,0,0.54)

  .more-issues
    .loadMoreButton
      margin-top 32px

    md-tabs
      md-tabs-wrapper
        padding 0 16px

      md-tabs-content-wrapper
        padding 16px

        md-tab-content
          padding 16px 8px

          md-grid-list
            .tile
              .cover
                width 100%
                height auto

              span
                margin-top 2px
                text-align center
                font-size 80%

              .title
                font-weight bold

div._md-select-menu-container
  z-index 99 // 98 is not enough


.interval
  color #555
  font-size .7em
  line-height 14px
  width 100%

.catalog-filter-titles
  margin 20px 0 20px 0
  md-input-container
    margin 0 5px 0 0
    padding 0
    ng-md-icon
      display inline-block;
      transform translateY(3px);
      svg
        height 16px
        width 16px
