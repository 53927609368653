_:-ms-fullscreen, :root .fix-forgot
  min-height 206px

_:-ms-fullscreen, :root .fix-login
  min-height 264px

_:-ms-fullscreen, :root .fix-change
  height: 100% !important

_:-ms-fullscreen, :root #journal-tabs.inactive
  transform translateY(100%) !important
  margin-bottom 56px !important

_:-ms-fullscreen, :root .pdf-menu-bar .toolbar-loading
  display none !important