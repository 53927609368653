a
  text-decoration none
  color $orange
.footer a
  color $gray

.cursor
  cursor pointer
.pointer
  cursor pointer

  &:hover
    box-shadow 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)

.ims-footer
  .md-button
    min-width auto
    border-radius 0
    padding 5px
    margin 0
    color: $gray
    overflow visible

  &.mobile
    .md-icon-button
      vertical-align top
      margin-top 6px

.bg
  background-color $bg !important
  margin-bottom:20px;

.gray
  color $gray

.ims-error
  color red
  div
    margin-top 5px

.flag {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size contain;

  &.de {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAb0lEQVR42u3aMQHAQAzEsOOPrZz8Q2FEgwlo9qpdbgAAAAAAAAAAAAAAHAfY1qUAAAAAAAAAAAAAAAAA4G/rVAAAAAAAAAAAAAAAAABQrW+dCgAAAAAAAAAAAAAAAAAAGCUBAAAAAAAAAAAAALjWA6kBEAfC9SDsAAAAAElFTkSuQmCC');
  }

  &.en {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAACb0lEQVR42u2avUoDQRDHrxUCVnkSQdAmjYVtIBCwEHwAn8BnyAMEFBEs0hhT2ekTiGjjB2i0sNAUSSMoWIyZw5XNfbm7M3Mh3AwsKe643f39/5CZ2Y0AIKryiBSAAlAACkABKAAFoAAUgAKoOICndhu+RyOw4/X9AzZ3zyBa6bIME5fTabOGCa75VrdP4f5lApN+H67r9XgO/B33epACYB7iy8nontzCcuNwYQAsrR9A5/gmFhSFNd9/bDZtkWcBPDQafy9KuaEMAGs7gyLV7ZgFgBt863TgqlYDKTdIAnBUPY7R+DMNADe1P7iDr+EQpNwgBcBDdehfPEN94ygNwHwMNyblBm4Avqpv7Z3ba8kGgCPXDa0WyQ2cAEJUT6wlH4CUGzgAUFT3BsDtBioAqupBAELcgMCy3BAKgEt1EgAON4QA4FSdDIDqBh8AEqqzAQh1gysAKdVZAYS4wRWAlOoiAHzd4AJASvVCAFBi/AegpFAAqX5AlYYCUAAKQAEoAP0bVAA8qTCmpZieJgPTWDut9QUQ1wTTdNo8xzQb0+28fsNcagEsSH7bzDMLxwImr7Ps6wC7KsSCCwsv6jkFGYCr6rh4LG2xxHUFYCpMSTeQAPiojk0NbG74NkRMhSnlhiAAIapTW2JSbvAGEKo6R1NUwg3OAKiqc7bFOd3gBIBDde6DEdwUHsJQ3VAIgFN1qaMxqhtyAXCrLnk4SnFDCoCU6mUcj+e6weosJ92QAiClelkXJHzdUFgMcape9hUZVzdkApBQfR6XpFzckAKQpzpeNVvUa3JFbtCLkgpAASgABaAAFIACUAAKQAFUG8APtyfgJ+7gOvsAAAAASUVORK5CYII=');
  }

  &.fr {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAYElEQVR4nO3QQREAIAwEsZpCACJwiD8kUBP9XXZmDaRKkiRJUnbn/smne3uNDgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEQBSJIkSUqqAdocFIeB13V3AAAAAElFTkSuQmCC');
  }

}

/* Disable shadow on xs	 */
@media screen and (max-width: 599px)
  md-content.md-whiteframe-1dp, md-content .md-whiteframe-z1
    box-shadow none

*:focus
  outline 0
  outline none

img.branding
  &.fadeIn
    animation-name: fadeIn;
    animation-duration: .75s;

@keyframes fadeIn
  from
    opacity 0
  to
    opacity 1

.toolbar-loading
  ._md-container
    ._md-bar, ._md-dashed
      background-color $loading

  &.main-toolbar-loading
    position absolute
    top -5px
    z-index 100

  &.reader-toolbar-loading
    position absolute
    bottom 0
    z-index 100

// let user click on x while autocomplete suggestions are shown
// let user escape without choosing a suggestion
.md-scroll-mask
  display: none


.gratis-aktion-helper
  background-color $orange !important
  color white
  padding 20px
  font-size 1.5rem
  cursor pointer
  position absolute
  z-index 16 // somehow the button gets displayed behind the browse journal in IE (has z-index 15)
  right 20px
  bottom 20px
  border-radius 4px
  display flex
  flex-direction row
  align-items center
  &-icon
    color white
    fill white
    padding-right 10px
    height 24px



.popup
  width 800px
  max-width 100%
  .heading

    .rss i
      vertical-align top
  .cover-container
    overflow hidden
    .cover
      max-width 95%
      max-height 95%
  .related-regionals, .related-supplements
    .cover
      max-width 95%
      max-height 95%
