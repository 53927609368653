/* Mixin */
vcenter($pos = relative)
  position $pos
  top 50%
  -webkit-transform translateY(-50%)
  -ms-transform translateY(-50%)
  transform translateY(-50%)

clearfix()
  zoom 1
  &:after
  &:before
    content ""
    display table
  &:after
    clear both
