.annotation-header
  height 46px
  min-height 46px

.annotation-content
  .carousel
    overflow hidden
    position relative
    padding 0 32px
    margin 16px 0

    .carousel-container
      display flex
      align-items flex-end
      margin-left 0
      transition margin 0.5s

      .carousel-item
        margin 0 8px
        text-align center
        flex-shrink 0

        &:first-of-type
          margin-left 0

        &:last-of-type
          margin-right 0

        img
          height 225px

        &.cover
          img
            height 250px

        .title
          font-weight bold

    .handle
      position absolute
      top 0
      bottom 0
      width 32px
      cursor pointer
      display flex
      align-items center

      &.prev
        left 0
        justify-content flex-start
        background url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+")
        background -moz-linear-gradient(left, rgba(250,250,250,1) 0%, rgba(250,250,250,1) 25%, rgba(250,250,250,0) 100%)
        background -webkit-linear-gradient(left, rgba(250,250,250,1) 0%,rgba(250,250,250,1) 25%,rgba(250,250,250,0) 100%)
        background linear-gradient(to right, rgba(250,250,250,1) 0%,rgba(250,250,250,1) 25%,rgba(250,250,250,0) 100%)

      &.next
        right -1px
        justify-content flex-end
        background url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjkwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+")
        background -moz-linear-gradient(left, rgba(250,250,250,0) 0%, rgba(250,250,250,1) 75%, rgba(250,250,250,1) 100%)
        background -webkit-linear-gradient(left, rgba(250,250,250,0) 0%,rgba(250,250,250,1) 75%,rgba(250,250,250,1) 100%)
        background linear-gradient(to right, rgba(250,250,250,0) 0%,rgba(250,250,250,1) 75%,rgba(250,250,250,1) 100%)

      &.hidden
        cursor auto
        opacity .5

/* MOBILE */
@media (max-width: 960px)
  .annotation-content
    .carousel
      .carousel-container
        .carousel-item
          img
            height 175px

          &.cover
            img
              height 200px

          .title, .sub
            font-size 80%