/* Content */

// don't forget to enable/disable in src/reader/reader.module.js
enableTextSelection = false

.pdf-content
  md-progress-circular
    z-index 50

  &.mobile
    #reader-area
      .reader-article-container
        .close-article
          right 16px

  #reader-spinner
    width 20px
    position absolute
    height 20px
    right 10px
    top 10px
    padding 0

  pdf-viewer
    position absolute
    top 0
    bottom 0
    left 0
    right 0
    overflow auto
    background-color #ccc
    transition left 0.5s

    .doublePage
      display flex
      justify-content center
      align-items center
      margin 10px auto

      .page
        margin 0

    .page
      position relative
      margin 10px auto
      // box-shadow 0 0 10px #666
      background-color $white

      canvas
        position absolute

      .text-layer, .annotations-layer, .highlights-layer, .searchresults-layer, .article-layer
        height 100%
        width 100%
        position absolute
        top 0
        left 0

      .text-layer
        overflow hidden
        if enableTextSelection
          z-index 9
        else
          z-index 8

        div
          color transparent
          position absolute
          cursor text
          white-space pre
          transform-origin 0 0

          span
            white-space pre

        .highlight
          background-color rgba(255, 255, 0, 0.3) //rgba(180, 0, 170, 0.2)
          border-radius 4px

          &.begin
            border-radius 4px 0 0 4px

          &.end
            border-radius 0 4px 4px 0

          &.middle
            border-radius 0

          &.selected
            background-color rgba(0, 100, 0, 0.2)

      .page-preview
        position absolute
        z-index 5

      .annotations-layer
        z-index 10

        &.raised
          z-index 12

      .highlights-layer
        z-index 6

        &.raised
          z-index 11

      .searchresults-layer
        z-index 7

      .article-layer
        z-index 0

        &.articleviewable
          z-index 13

        div:hover
          background-color rgba(147, 232, 63, 0.1)

      .shield
        if enableTextSelection
          z-index 8
        else
          z-index 9

        position absolute
        top 0
        bottom 0
        left 0
        right 0

/* Context Menu */

.reader-context-menu-backdrop
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  z-index 100
  background-color rgba(0,0,0,0.1)

  .reader-context-menu
    position absolute
    background-color $white
    padding 0
    min-width 200px

    .md-subheader
      ._md-subheader-inner
        padding 16px 16px 8px 16px

    md-list-item
      height 36px
      min-height 36px

      md-icon
        margin 0 16px 0 0 !important

/* ---------- ---------- ---------- */

pdf-viewer .page .annotLink > a
  position: absolute
  font-size: 1em
  top: 0
  left: 0
  width: 100%
  height: 100%

pdf-viewer .page .annotLink > a:hover
  background-color: rgba(255, 255, 0, 0.25)

pdf-viewer .page .annotText > img
  position: absolute
  cursor: pointer

pdf-viewer .page .annotTextContentWrapper
  position: absolute
  width: 20em

pdf-viewer .page .annotTextContent
  z-index: 200
  float: left
  max-width: 20em
  background-color: #FFFF99
  box-shadow: 0 2px 5px #333
  border-radius: 2px
  padding: 0.6em
  cursor: pointer

pdf-viewer .page .annotTextContent > h1
  font-size: 1em
  border-bottom: 1px solid #000000
  padding-bottom: 0.2em

pdf-viewer .page .annotTextContent > p
  padding-top: 0.2em

pdf-viewer .page .singlePageProgressIndicator
  position: absolute
  left: 50%
  margin-left: -25px

.reader_color_selector,
.reader_item_selector
  padding: 0
  margin: 0
  list-style: none
  display: -webkit-box
  display: -moz-box
  display: -ms-flexbox
  display: -webkit-flex
  display: flex
  -webkit-flex-flow: row
  justify-content: space-around

  .item
    margin: 5px
    text-align: center
    flex: 1 0 auto
    height: auto
    cursor: pointer
    outline:2px solid rgba(0,0,0,0)
    transition: outline 0.15s

    &.selected
      outline:2px solid rgba(0,0,0,0.75)

    &.disabled
      background-color: $gray !important

  .item:before
    content: ''
    float: left
    padding-top: 100%

.reader_item_selector .item
  outline:none
  transition: color 0.15s

  md-icon
    transition: color 0.15s

  &.selected
    outline: none

  &:before
    padding: 0

.outlineItemBold
  font-weight bold

.outlineItemItalic
  font-style italic

.md-icon-button.side-button
  height 100%
  margin 0
  border-radius 0

expand-collapse
  t = 0.5s
  faster = t * 0.8
  twiceAsFast = t * 0.5
  width 100%

  .outlineItem
    width 100%

    .trigger
      transition transform twiceAsFast ease-out
      padding-right: 8px

    .trigger.enabled
      transform rotate(90deg)
      transition transform twiceAsFast ease-in

    .trigger.disabled
      visibility hidden

    md-list-item
      padding-right 0
      padding-left 8px

    .outlineItemChildren.shown
      opacity 1
      max-height 999px
      transition all t ease-in, opacity faster ease-in

    .outlineItemChildren.hidden
      overflow hidden
      opacity 0
      max-height 0
      transition all t ease-out, opacity faster ease-out

  .outlineItemLink
    cursor pointer

::selection { background: rgba(0,0,255,0.3) }
::-moz-selection { background:rgba(0,0,255,0.3) }

.reader-article-container
  width: 100% // IE

/* Print */

@media print
  .md-sidenav-left, .reader-icon-menu, .reader-icon-menu-details, .pdf-menu-bar, .reader-context-menu-backdrop, md-tooltip, ._md-subheader-wrapper, ._md-open-menu-container,
  .text-layer, .annotations-layer, .highlights-layer, .searchresults-layer, .article-layer, .reader-article-container, #reader-spinner, .pagination-bar, .edit-note-container
    display none !important

  html, body
    height auto

  .pdf-content pdf-viewer.side-open
    left 0

  .doublePage, pdf-viewer, #reader-area, .pdf-content, section, div, md-content, body
    float none !important
    border none !important
    box-shadow none !important
    position relative !important

  .flex, .layout-column, .layout-row
    display block !important

  .page
    height calc(100vh - 16mm) !important
    width 100vw !important
    max-width: calc((100vh - 16mm) / 1.4142) !important
    margin: 8mm auto 8mm auto !important;
    position absolute
    top 0
    left 0
    bottom 0
    /*padding-top 8mm !important
    padding-bottom 8mm !important*/
    &:before, &:after
      left 0 !important
      right 0 !important
      position absolute !important
      z-index 99999 !important
      display block !important
      height 8mm !important
      line-height 8mm !important
      font-size 12pt !important
      padding 0 3mm !important
      background-color rgba(255,255,255,.8)
      text-align center
    &:before
      content 'ims MediaPool - ' attr(data-issue) ' ' attr(data-page) !important
      top -8mm !important
    &:after
      content attr(data-url) !important
      bottom: -8mm !important;

    canvas, .page-preview
      height 100% !important
      width 100% !important

  .doublePage
    display block !important
    width auto !important
    margin 0 !important

    .page
      page-break-after always
      page-break-inside avoid

  _::-webkit-:host:not(:root:root), .page // ios fix
    height 290mm !important

/* TABLET */

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px)
  md-tooltip
    display none

/* IE fix */

.reader-cover-list
  position: relative

  md-list-item > div.md-list-item-text
    width 100%
    height 100%

    div.image-wrapper
      height 100%

md-list-item.md-no-proxy, md-list-item .md-no-style
  position: relative
  -webkit-box-flex: 1
  -webkit-flex: 1 1 auto
  flex: 1 1 auto
  padding: 0


#pdfviewer .searchresults-layer
  display none
#pdfviewer.search-open .searchresults-layer
  display block
