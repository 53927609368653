.warn
  color $warn
  padding 10px 0
  font-size 90%

.footer, md-content.footer
  font-size 85%
  color $gray
  padding 10px 0
  cursor pointer
  width 100%
  div
    margin 0 auto
    display table

._md-subheader-inner
  background-color $white

  ._md-subheader-content
    ng-md-icon
      height 24px
      vertical-align middle
      display inline-block

.partials-header
  height 56px
  min-height 56px


.feedback, .unsubscribe
  .box
    padding 20px
    box-shadow 0 0 15px 0 #999
    background-color white
    margin-top 20px
    md-input-container
      margin 10px 0
      .md-errors-spacer
        display none
    &.box-padding-10
      padding 10px
    .emphasized
      font-style oblique
      font-weight 300
  h2
    font-weight normal
    margin-top 0
    font-size 130%
    color $darkblue

.feedback
  background-image url(assets/images/bg.jpg)
  background-size cover
  min-height calc(100vh - 48px)
  p
    color $darkblue
  .feedback-contact
    display flex
    flex-direction column
    align-items center
    margin-top 40px
    a
      font-size 120%
    .feedback-contact-icon svg
      width 36px
      height 36px
      fill $darkblue