a.ims
  color: #0067b2
  font-weight: bold
  text-decoration underline

.login
  font-size 1rem
  line-height 1.6
  .ims
    color #0067B2
    font-weight bold

.login-header-xs
  ._md-subheader-inner
    padding 13px 16px

    .login-header-logo
      height 30px

md-content.login-content.md-brand_default_theme-theme
  background-color $white

.login-content
  padding-top 20px
  &.md-background
    background-color $white
  h1
    font-size 120%
    font-family 'Open Sans', sans-serif
    color #666
    font-weight normal

  .login-box
    width 90%
    margin 0 auto
    align-items center
    justify-content space-evenly

    @media (min-width: 960px)
      .login-box-left
        margin-right 50px

    .video-container
      background rgba(0, 0, 0, 0)
      position relative
      .image-container
        max-width:100%
        max-height:100%;
        .thumbnail
          width:100%
          height: 100%
        .playbutton
          position absolute
          width 10%
          top 0
          left 0
          right 0
          bottom 0
          margin auto
          cursor pointer
      p
        position absolute
        margin auto
        text-align center
        color #666
        font-weight 300
        bottom 0
        font-size 0.75rem
        left 0
        right 0
        background-color rgba(0,0,0,.05)
        @media screen and (max-width:464px)
          font-size 0.5rem
          bottom 2.5px


      @media (max-width: 959px)
        overflow hidden
        margin-top 10px
        padding-bottom 56.25%
        position relative;
        height 0

      iframe, .video-cover
        @media (min-width: 1230px)
          width 700px
          height 394px
        @media (max-width: 1229px)
          width 500px
          height 281px
        @media (max-width: 959px)
          left 0
          top 0
          height 100%
          width 100%
          position absolute

  .login-logo-wrapper
    padding 20px
  //margin-right 100px
  //margin-top 40px
  //margin-left 100px
  .login-logo
    height 100px

  .fix-login
    padding 20px
    // background-color $white
    form
      padding 20px
      padding-top 30px
      box-shadow 0 0 15px 0px #999999;
      background-color rgba(255, 255, 255, .8)

.forgot-content
  background-color #fafafa

  .fix-forgot
    md-content
      background-color $white

      form
        margin-top 8px

        a
          text-transform uppercase

.change-content
  .fix-change
    .md-button.md-primary.md-raised:not([disabled]):hover
      opacity 0.75

.terms
  md-checkbox
    &.md-checked ._md-icon
      background-color $green
    &:not(.md-checked) ._md-icon
      border-color $warn

.jumbotron
  width 100%
  background-image url(assets/images/header_mediapool-2.jpg)
  background-position center
  background-size cover
  position relative
  .hide-for-large-up
    display none !important
  .headertext
    position absolute
    top 20%
    left 10%
    h1
      font-size 120%
      font-family 'Open Sans', sans-serif
      color #666
      font-weight normal
    .logo
      width 200px

h2.coverslider-header
  font-family 'Open Sans', sans-serif
  color #003b66
  background-color #eeeeee
  text-align center
  font-weight 300
  margin 0 0 40px 0
  padding-top 60px
  padding-bottom 40px
  margin-bottom 0
  font-size 2.25rem
  font-style normal

#coverslider
  text-align center
  background-color #eeeeee
  padding-bottom 40px
  .slider
    width 950px
    max-width: 90%
    margin 0 auto
    text-align left
  .slider, .frame
    position relative
    font-size 0
    line-height 0
    overflow hidden
    white-space nowrap
    height 240px
  .frame
    //width 830px
    margin-left 35px
    margin-right 35px
  .slides
    display inline-block
    height 240px
    padding 0
    li
      position relative
      display inline-block
      width 150px
      margin 15px
      height 200px
  .prev, .next
    position absolute
    top 50%
    margin-top -25px
    display block
    cursor pointer
  .next
    right 0
  .prev
    left 0
  .next svg, .prev svg
    width 25px
  li img
    position absolute
    width 150px
    transform translateY(-50%)
    top 50%

.login
  .login-description
    text-align center
    max-width 900px
    width 90%
    margin 0 auto
    margin-bottom 40px
  .coverslider-content
    background-color #eeeeee

.coverslider-description
  text-align center
  max-width 900px
  width 90%
  margin 0 auto
  margin-bottom 20px
  margin-top -40px
  padding-bottom 20px

@media (min-width: 872px) and (max-width: 1060px)
  #coverslider
    .slider
      width 770px

// 4 covers

@media (min-width: 661px) and (max-width: 870px)
  #coverslider
    .slider
      width 590px

// 3 covers

@media (min-width: 465px) and (max-width: 660px)
  #coverslider
    .slider
      width 410px

// 2 covers

@media (max-width: 464px)
  #coverslider
    .slider
      width 230px

// 1 covers

@media (max-width: 959px) // this equals sm and lower, workaround for safari flexbox bugs
  .login-logo-wrapper, .fix-login
    width 400px
    max-width 90%
    display block
    margin 0 auto

.show-password {
  float: right;
  margin-right: 6px;
  margin-top: -25px;
  position: relative;
  z-index: 2;
  svg {
    height: 18px
    width 18px
  }
}

