::-webkit-input-placeholder
  color #0067b2  !important

:-moz-placeholder
  color #0067b2  !important

::-moz-placeholder
  color #0067b2  !important

:-ms-input-placeholder
  color #0067b2  !important

/* TOOLBARS */
.pdf-menu-bar, .ims-menu-bar
  height 48px
  min-height 48px
  box-shadow none
  z-index 99

  &.mobile
    box-shadow none

  .md-toolbar-tools
    padding 0 8px

    md-icon
      color $white
      text-rendering optimizeLegibility
      -webkit-font-smoothing antialiased

    ng-md-icon
      fill $white

    .md-icon-button
      margin 0

    md-input-container
      margin 0
      padding 0

      .md-input
        border-color $white
        color $white

    span
      color $white

/* IMS MENU BAR */

.ims-menu-bar
  .toolbar-search
    position relative
    background white
    border-radius 18px
    padding 0
    height 30px
    md-autocomplete
      height 30px
      width 200px
      border-top-right-radius 18px
      border-bottom-right-radius 18px
      &#mobileSearchBar
        border-radius 18px
        padding-left 10px

      md-autocomplete-wrap
        height 30px
        box-shadow none
        //border-bottom 1px solid $white

        button
          md-icon svg path
            fill 0067b2

        input
          height 30px
          padding 0
          color: #0067b2
    /*.searchIconButton
      position absolute
      z-index 2
      right 0
      margin-left 79px !important*/

    .searchIcon
      cursor pointer
      fill #0067b2
      svg
        fill #0067b2

/* PDF MENU BAR */

.pdf-menu-bar
  .md-toolbar-tools
    .toolbar-search
      .toolbar-search-box
        height 60px
        padding 0 8px 8px 8px
        position absolute
        top 48px
        right 0
        visibility hidden
        opacity 0
        z-index 50
        background-color white
        transition all 0.3s

        &.active
          visibility visible
          opacity 1

        md-input-container
          input
            width 250px
            font-size 1rem

        .search-count
          position absolute
          bottom 2px
          right 12px
          font-size 12px
      .searchresults
        position absolute
        top 60px
        left 0
        right 0
        max-height calc(100vh - 108px)
        overflow-y auto
        font-size 10pt
        list-style-type none
        margin 0
        padding 0
        background-color white
        li
          padding 5px
          margin 10px
          cursor pointer
          user-select none
          border-left 4px solid rgba(0,0,0,0)
          &.onpage
            border-left-color #509171
            &.active
              background-color rgba(255,255,255,.3)
              border-left-color #ff6949
          div
            font-weight bold
          p
            margin 0
            padding 0
            em
              font-style normal
              text-decoration underline
              text-color var(--primary-color)

    .toolbar-action
      button
        margin 0 8px

    .toolbar-pagination
      md-input-container
        input
          width 50px
          text-align right

    .toolbar-zoom
      md-input-container
        md-select
          width 180px
          font-size 16px

          md-select-value
            border-bottom solid $white 1px

    .toolbar-article
      md-icon
        color $gray
        &.active
          color $white


.toolbar-menu-content
  padding 0

  md-menu-item
    ng-md-icon
      height 24px
      width 24px
      padding 12px
      fill rgba(0, 0, 0, 0.54)

    span
      padding 12px 12px 12px 0

/* BROWSE SIDE MENU */

.browse-side-menu
  top 48px

  md-list
    padding 0

    .username
      font-size 18px
      padding 17px 0

    md-list-item
      .md-button
        height 56px

/* READER ICON MENU */

.reader-icon-menu
  position absolute !important
  top 48px
  width 40px
  bottom auto

  md-content
    background-color transparent !important

  md-list
    padding 0

    md-list-item
      min-height 40px

      .md-icon-button
        height 40px
        width 100%
        margin 0

/* READER ICON MENU DETAILS & MOBILE READER SIDE MENU */

.reader-icon-menu-details
  width 240px

.reader-side-menu
  top 48px

  .back
    position absolute
    top 0
    right 0
    margin 4px 8px
    z-index 10
    fill rgba(0,0,0,0.54)

  md-tabs
    md-tab-item
      ng-md-icon
        fill rgba(0,0,0,0.54)

.reader-icon-menu-details, .reader-side-menu
  .reader-cover-list
    .image-wrapper
      margin 8px 16px

      .md-icon-button
        position absolute
        top -10px
        right 10px
        border-radius 0

        md-icon
          font-size 32px

      img
        width 100%

  .reader-note-help
    padding 16px

    p
      margin 0

  .reader-note-container
    .image-wrapper
      margin 16px

      .reader-note-thumbnail
        width 100%

    .reader-note-list
      padding 0

      .note-list-item
        flex-grow 1

        md-icon
          margin 0

        .text
          margin 0
          padding 8px
          line-height initial
          flex-grow 1
          max-width calc(100% - 48px)
          word-wrap break-word

        .delete-note
          cursor pointer
          z-index 20

          &:hover
            color red

/* READER ICON MENU DETAILS OLD

.reader-icon-menu-details
  .reader-annotation-list
    padding 0

    .annot-empty
      text-align center
      padding 32px

    ng-md-icon
      height 24px

    .text
      max-width calc(100% - 48px)
      padding 8px

      p, h3
        margin 0
        line-height 24px

        &:empty
          display none

    .delete
      z-index 10

      &:hover
        color $warn

  .reader-content-list
    md-list-item
      min-height 32px
*/

.edit-note-container
  width 300px
  position relative
  background $bg
  z-index 99
  padding 24px 16px 16px 16px

  .close-note
    position absolute
    top 5px
    right 5px
    cursor pointer

  textarea#note-textarea
    min-height 60px

  md-input-container
    margin 0

    .md-errors-spacer
      display none

    .md-resize-handle
      bottom 0

md-switch.md-brand_1_theme-theme ._md-thumb
  background-color rgb(228,228,228)


md-autocomplete .md-show-clear-button button
  position: relative;
  line-height: 20px;
  text-align: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  padding: 0;
  font-size: 12px;
  background: transparent;
  margin-right: 30px;
