#article-content { margin: 0; padding: 0; transition: background .3s linear, color .1s linear; }
#article-content { margin: 0 auto; position: relative; z-index: 1; }

.articleview-options-button { cursor: pointer; }
svg.articleview-options-button * { pointer-events: none; }

/* article styling */
#article-content figure { max-width: 100%; margin: 0 auto; padding: 0; text-align: center; }
#article-content figure + figure { margin-top: 10px; }
#article-content figure figcaption { font-size: 90% !important; font-style: italic; line-height: 110%; margin-top: 5px; text-align: left; }
#article-content figure img, #article-content p > img, #article-content > img { max-width: 100%; display: block; }
#article-content .readingtime { font-style: italic; font-family: sans-serif; }

#article-content h1 { display: none; }
#article-content h2 { font-size: 120%; }
#article-content h3 { font-size: 100%; }
#article-content h2.main-title { font-size: 200%; }
/*#article-content a.pagemarker {
	position: absolute; right: 0;
	transform: translateX(100%);
	font-size: 80%;
	font-weight: bold;
	border-left: 3px solid grey;
	height: 100px;
	padding-left: 10px;
	font-family: sans-serif;
	color: grey;
}*/#article-content a.pagemarker { display: none; } /* Braucht man die?? */


/* menu */
.articleview-options-menu {
  max-height: 0;
  overflow: hidden;
  pointer-events: none;
}
.articleview-options-menu.active {
  max-height: 200px;
  pointer-events: auto;
}

.articleview-menu { padding: 10px; font-family: sans-serif; font-size: 11px; }
.articleview-menu > div { display: flex; justify-content: center; align-items: center; border-bottom: 1px solid #707070; height: 55px; padding: 10px 0; }
.articleview-menu > div:first-child { justify-content: space-between; padding-top: 0; }
.articleview-menu > div:last-child { justify-content: space-around; padding-bottom: 0; border-bottom: 0; }
.articleview-menu > div:first-child > div { display: flex; }

.articleview-menu svg { vertical-align: middle; }

/* Sliders */
.articleview-menu > .articleview-menu-slider > div > svg:first-child { height: 12px; width: 12px; margin: 0 10px; }
.articleview-menu > .articleview-menu-slider > div > svg:last-child { height: 24px; width: 24px; margin: 0 10px; }
.articleview-menu > div.articleview-menu-slider input[type="range"] {
  -webkit-appearance: none;
  height: 2px;
  width: 150px;
  outline: none;
  padding: 0;
  margin: 0;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { /* target IE only */
  .articleview-menu > div.articleview-menu-slider input[type="range"] {
    height: auto;
  }

}
.articleview-menu > div.articleview-menu-slider input[type="range"]::-ms-fill-lower { background-color: #78BD20; }
.articleview-menu > div.articleview-menu-slider input[type="range"]::-ms-track { background-color: transparent; color: transparent; border-width: 0; }
.articleview-menu > div.articleview-menu-slider input[type="range"] { background-color: #78BD20; }
.articleview-menu > div.articleview-menu-slider input[type="range"]::-moz-range-track {
  background: #78BD20;
  border: 0;
}
.articleview-menu > div.articleview-menu-slider input[type="range"]::-moz-focus-inner, .articleview-menu > div.articleview-menu-slider input[range]::-moz-focus-outer {
  border: 0;
}

.articleview-menu > div.articleview-menu-slider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #78BD20;
  background: white;
  cursor: pointer;
  -webkit-transition: all .15s ease-in-out;
  transition: all .15s ease-in-out;
}
.articleview-menu > div.articleview-menu-slider input[type="range"]::-moz-range-thumb {
  width: 16px;
  height: 16px;
  border: 0;
  border-radius: 50%;
  border: 1px solid #78BD20;
  background: white;
  cursor: pointer;
  -webkit-transition: background .15s ease-in-out;
  transition: background .15s ease-in-out;
}
.articleview-menu > div.articleview-menu-slider input[type="range"]::-webkit-slider-thumb:hover {
  width: 24px;
  height: 24px;
}
.articleview-menu > div.articleview-menu-slider input[type="range"]::-moz-range-thumb:hover {
  width: 24px;
  height: 24px;
}



.articleview-menu > div:last-child > div { display: flex; flex-direction: column; align-items: center; text-align: center; }
label { }
.articleview-menu svg circle, .articleview-menu svg path { fill: currentColor; }
.articleview-menu svg line { stroke: currentColor; fill:none; stroke-linecap:round; stroke-miterlimit:10; }

.articleview-menu button {
  border: 2px solid #343434;
/*  box-shadow: 0 0 3px 0 #343434; */
  border-radius: 50%;
  height: 32px;
  width: 32px;
  text-align: center;
  outline: none;
  padding: 0;
  margin: 3px;
  line-height: 28px;
  display: inline-block;
}
.articleview-menu button.default-style {
  background: #343434;
  color: white;
}
.articleview-menu button.active, .articleview-menu .articleview-menu-slider-button.active button {
  border-color: #78BD20;
}
.articleview-menu button:hover {
  opacity: .8;
  /*box-shadow: 0 0 10px 0 #343434;*/
}

.articleview-menu .articleview-menu-slider {
  max-height: 0;
  overflow: hidden;
  padding: 0;
  border-bottom-width: 0;
  transition: max-height .15s linear;
}
.articleview-menu .articleview-menu-slider > div {
	opacity: 0;
	transition: opacity .15s ease-in .15s, transform .2s linear;
}

.articleview-menu .articleview-menu-slider.active > div {
	position: absolute;
	opacity: 1;
	white-space: nowrap;
	width: 100%;
	height: 50px;
	line-height: 45px;
	top: 50%;
	left: 50%;
	margin-top: -25px;
	margin-left: -50%;
	text-align: center;
}

/*@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { /* target IE only */
/*	.articleview-menu .articleview-menu-slider.active > div {
/*		top: 18px;
/*		left: 10px;
/*	}
/*}
*/
.articleview-menu .articleview-menu-slider > div:first-child { transform: translateX(-100%); }
.articleview-menu .articleview-menu-slider > div:nth-child(2) { transform: translateX(-100%); }
.articleview-menu .articleview-menu-slider > div.active ~ div:nth-child(2)   { transform: translateX(100%); }
.articleview-menu .articleview-menu-slider > div:last-child { transform: translateX(100%); }

.articleview-menu .articleview-menu-slider.active > div.active {
	transform: translateX(0) !important;
}


.articleview-menu .articleview-menu-slider.active {
  max-height: 55px;
  border-bottom-width: 1px;
  position: relative;
}

/* user settings */
.color-model-white-black { color: #EEEEEE; background-color: #232323; }
.color-model-sepia { color: #443322; background-color: #FFEEDD; }
.color-model-black-white { color: #232323; background-color: #FFFFFF; }


.margin-0 #article-content { max-width: 100%; }
.margin-1 #article-content { max-width: 100%; width: 70em; }
.margin-2 #article-content { max-width: 100%; width: 60em; }
.margin-3 #article-content { max-width: 100%; width: 50em; }
.margin-4 #article-content { max-width: 100%; width: 40em; }
.margin-5 #article-content { max-width: 100%; width: 35em; }
.margin-6 #article-content { max-width: 100%; width: 30em; }
.margin-7 #article-content { max-width: 100%; width: 25em; }
.margin-8 #article-content { max-width: 100%; width: 20em; }
.margin-9 #article-content { max-width: 100%; width: 15em; }

.fontsize-0  { font-size: 6pt; }
.fontsize-1  { font-size: 7pt; }
.fontsize-2  { font-size: 8pt; }
.fontsize-3  { font-size: 9pt; }
.fontsize-4  { font-size: 10pt; }
.fontsize-5  { font-size: 11pt; }
.fontsize-6  { font-size: 12pt; }
.fontsize-7  { font-size: 14pt; }
.fontsize-8  { font-size: 16pt; }
.fontsize-9  { font-size: 18pt; }
.fontsize-10 { font-size: 20pt; }
.fontsize-11 { font-size: 22pt; }
.fontsize-12 { font-size: 24pt; }
.fontsize-13 { font-size: 26pt; }
.fontsize-14 { font-size: 28pt; }
.fontsize-15 { font-size: 30pt; }
.fontsize-16 { font-size: 32pt; }
.fontsize-17 { font-size: 36pt; }
.fontsize-18 { font-size: 40pt; }

.line-spacing-5  { line-height: 100%; }
.line-spacing-6  { line-height: 110%; }
.line-spacing-7  { line-height: 120%; }
.line-spacing-8  { line-height: 130%; }
.line-spacing-9  { line-height: 140%; }
.line-spacing-10 { line-height: 150%; }
.line-spacing-11 { line-height: 170%; }
.line-spacing-12 { line-height: 190%; }
.line-spacing-13 { line-height: 200%; }
.line-spacing-14 { line-height: 225%; }
.line-spacing-15 { line-height: 250%; }

.line-spacing-5  h2.main-title { line-height: 100%; }
.line-spacing-6  h2.main-title { line-height: 105%; }
.line-spacing-7  h2.main-title { line-height: 110%; }
.line-spacing-8  h2.main-title { line-height: 115%; }
.line-spacing-9  h2.main-title { line-height: 120%; }
.line-spacing-10 h2.main-title { line-height: 125%; }
.line-spacing-11 h2.main-title { line-height: 130%; }
.line-spacing-12 h2.main-title { line-height: 140%; }
.line-spacing-13 h2.main-title { line-height: 150%; }
.line-spacing-14 h2.main-title { line-height: 160%; }
.line-spacing-15 h2.main-title { line-height: 170%; }
