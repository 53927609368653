.search-view
  height 100%
  width 100%
  position absolute
  overflow-x scroll

  .item
    transition all 0.75s

    &.item-issue
      height 344px

    &.item-feed
      height 283px

    &.inactive
      height 0 !important

    &.active
      height calc(100% + 1px) !important

      .search-container
        overflow auto

    .search-container
      height calc(100% - 64px)
      overflow hidden

      .cover-container
        float left
        max-height 260px
        max-width 180px
        padding 10px 0 10px 15px

        div
          text-align center

          .title
            font-weight bold
            text-overflow ellipsis
            overflow hidden
            white-space nowrap
            margin 0

          .info, .date
            font-size 75%

        img
          max-width inherit
          width 100%

      .newsfeed-container
        float left
        width 50%
        min-height 220px
        padding 15px
        border-bottom solid #eee 1px

        &:nth-child(even)
          border-left solid #eee 1px

        .newsfeed-cover-container
          min-width: 150px
          margin-right: 15px

          div
            width: 150px
            height: 150px
            overflow: hidden
            text-align: center
            margin-right: 15px
            background-size: cover
            background-position: center center
            background-repeat: no-repeat
            background-color #eee

        .newsfeed-details-container
          position relative
          width: 100%

          .md-subhead
            margin 5px 0 10px 0

          .feedText
            margin 0
            overflow hidden
            height 4.8em
            line-height 1.2em

            p
              height calc(4.8em - 1px)
              overflow hidden
              margin 0

            img, a
              display none

  .empty-list
    color #ccc
    text-transform uppercase
    text-align center
    margin-top 5%
    font-weight 600
    margin-bottom 5%

  .loadMore
    position: relative
    width: 150px
    height: 200px

  .loadingMoreHitsProgressIndicator
    position: absolute
    left: 50%
    margin-left: -25px

  .loadMoreButton
    position: relative
    width: calc(100% - 16px)

@media (max-width: 960px)
  .search-view
    overflow auto

    .item
      &.item-issue
        height 336px

      &.item-feed
        height 275px

      .search-container
        height calc(100% - 56px)

        .newsfeed-container
          width auto
          border-left none !important

          .newsfeed-cover-container
            min-width: 75px

            div
              height 75px
              width 75px

          .newsfeed-details-container
            font-size 80%


@media (min-width: 960px) {
  .issue-container {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width 916px;
    width 916px;
    margin: auto;
    min-height: 100px;
  }

}

@media (max-width: 959px) {
  .issue-container {
    margin: 10px 50px 50px 50px;
  }

}


.search-results {
  border-radius: 5px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .3);

  padding 30px;
  position: relative;
  background-color: #EDEDED;
  font-family: Roboto, Helvetica, sans-serif;
}

@media (max-width: 959px) {
  .search-form {
    margin: 10px 10% 50px 10%;
    display flex
    flex-direction column
    align-items  center
  }
  .search-toolbar {
    padding-left: 25%;
    padding-right: 25%;

    & > .b1c {
      font-size:15px;
    }
  }
}

@media (min-width: 960px) {
  .search-form {
    display flex
    flex-direction row;
    align-items flex-end
    margin: 10px 15% 50px 15%;
  }

  .search-toolbar {
    padding-left: 25%;
    padding-right: 25%;
  }
}


@media (max-width: 959px) {
  .search-cover {
    max-height: 100%;
    max-width: 120px;
    border-radius: 5px;

  }
}

@media (min-width: 960px) {
  .search-cover {
    max-height: 100%;
    max-width: 100%;
    border-radius: 5px;
    width: 150px;
  }
}


@media (max-width: 959px) {
  .issue h1 {
    position: absolute;
    left: 150px;
    top: 10px;
    right: 10px;
    bottom: 50px;
    font-size: 20px;
    line-height: 18px;
    margin: 0;
    font-weight: bold;
    cursor: pointer;
  }
}

@media (min-width: 969px) {
  .issue h1 {
    font-size: 20px;
    line-height: 18px;
    font-weight: bold;
    margin-top: 0;
    cursor: pointer;
  }
}


.search-result {
  max-width: 100%;
  overflow: hidden;
  margin-bottom: 25px;

  em {
    font-weight: bold !important
    color: #0067b2 !important;
  }
}

.pageExtSearch {
  margin-bottom: 15px;
  font-weight: bold;
  width: 52px;
}

@media (max-width: 959px) {
  .res {
    position: block;
    left: 180px;
    top: 80px;
    right: 10px;
    bottom: 50px;
    font-size: 14px;
    line-height: 18px;
    margin: 0;
    font-weight: bold;
  }

  .dateExtSearch {
    position: absolute;
    left: 150px;
    top: 40px;
    right: 10px;
    bottom: 50px;
    font-size: 14px;
    line-height: 18px;
    margin: 0;
    font-weight: bold;
    margin-top 10px
  }

  .findingPlace {
    margin-left: 20px
  }

  .search-more-results {
    margin-top: 10px;
  }

  .pageExtSearch {
    display: inline-block;
    margin-bottom: 10px;
    font-weight: bold;
    font-width:14px;
    width: 60px;
    margin-top 16px
  }

  .issue {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .3);

    padding: 10px;
    position: relative;
    background-color: #EDEDED;
    font-family: Roboto, Helvetica, sans-serif;

    max-height: 100%;
    border-radius: 5px;
    //height: 600px;
    max-width 916px;
    //width 916px;
    margin-bottom: 35px;
  }

}


@media (min-width: 960px) {
  .res {
    position: block;
    font-size: 14px;
    line-height: 18px;
    margin-top: 17px;
    font-weight: bold;
  }

  .dateExtSearch {
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    margin-top 10px
  }

  .findingPlace {
    margin-left: 16px
  }

  .pageExtSearch {

    margin-bottom: 15px;
    font-weight: bold;
    font-size: 14px;
    width: 60px;
  }

  .issue {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .3);
    display:flex;
    padding: 10px;
    position: relative;
    background-color: #EDEDED;
    font-family: Roboto, Helvetica, sans-serif;


    border-radius: 5px;
    min-height: 100px;
    max-width 916px;
    width 916px;
    margin-bottom: 35px;
  }
}

.cite
  position relative
  text-overflow ellipsis
  font-weight normal
  overflow hidden
  cursor pointer
  display block
  max-width 95%
  margin-top 5px



.arrow {
  position relative
  display: flex;
  align-items: center;
  justify-content: center;
  min-height 29px !important;
  cursor: pointer;
}

@media (min-width: 960px) {
  .read-button {
    position: relative;
    font-size: 16px;
    margin: 0;
    font-weight: normal;
    width: 150px;
    max-width: 100%;
    top: 5px;
  }
}

@media (max-width: 959px) {
  .read-button {
    position: relative;
    display block;
    font-size: 16px;
    margin: 0;
    font-weight: normal;
    max-width: 100%;
    top: 5px;
  }
}


.loadingMoreIssuesProgressIndicator
  padding 0 0 0 0 !important
  display block
  background-image url(assets/images/signet.svg)
  background-position center center
  background-repeat no-repeat
  background-size 50%

  svg
    position relative

  &:not([md-diameter]) svg path
    stroke-width 3px !important

.loadMoreIssues
  width: 50%
  margin: 0 auto


.loadMoreButtonExtSearch
  display: block
  margin: 0 auto
  width: 250 px

  .md-calendar-scroll-container
    width 100% !important



.search-buttons-container
  position relative

.search-alert
  width 250 px
  display inline-block

.search-alert-container
  text-align center

.search-button
  display block
  margin 10px auto
  text-align center

@media (max-width: 959px) {
  .dateRow {
    margin-left: -15px;
    display block;
  }
}

@media (min-width: 960px) {
  .dateRow {
    margin-left: -15px;
  }
}

md-input-container .md-input.simple-search-input
  width calc(100% - 40px)
  margin-bottom 20px


.searchIconButtonOr
  position: absolute
  margin-right: -20px

.info
  position absolute
  margin-left -30px !important

.rightContainer
    margin-left 15px
    flex-basis 100%
  .new-flag
    position absolute
    top -5px
    right 15px
    background-color $orange
    color white
    font-weight bold
    padding 10px 5px 3px 5px
    box-shadow 0 2px 5px 2px rgba(0,0,0,.2)
    text-transform uppercase

@media (min-width: 960px) {
  .leftContainer {
    max-width: 150px;
  }
}

