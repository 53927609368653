.titles
  font-size 1rem
  line-height 1.6
  .ims
    color #0067B2
    font-weight bold
  .desc
    background-color #eee
    padding 20px
    padding-bottom 40px

  text-align center
  md-toolbar.md-brand_default_theme-theme
    //background-color #eeeeee
    //color #003b66
    svg
      fill currentColor
      vertical-align bottom
  .toolbar-search md-input-container.searchinput
    .md-errors-spacer
      display none
      /*input
        color #003b66
        &::placeholder
          color #003b66 !important*/
    &.sticky
      position sticky
      top 0
  @media screen and (min-width 600px)
    .toolbar-search .backbutton
      position absolute
      margin-left 200px
  .titles-login
    padding-bottom 50px
  .cover
    max-width 100%
    max-height 100%
    display block
  .titles-list
    min-height 50vh
  h2
    font-family 'Open Sans', sans-serif
    color #003b66
    background-color #eeeeee
    text-align center
    font-weight 300
    margin 0 0 40px 0
    padding-top 60px
    margin-bottom 0
    font-size 2.25rem
    font-style normal

.titles-detail
  width 600px
  max-width 100%
  .heading
    min-height 230px
    .rss i
      vertical-align top
  .cover-container
    overflow hidden
    .cover
      max-width 95%
      max-height 95%
  .related-regionals, .related-supplements
    .cover
      max-width 95%
      max-height 95%

.search-suggestions li
  padding-left 0
  padding-right 0
  .search-suggestion-type-MAGAZINE, .search-suggestion-type-ISSUE
    background-color #EFEFEF
  .search-suggestion-type-ISSUE:hover
    background-color #E0E0E0
  .search-suggestion-type-MAGAZINE // this issue is not subscribed
    opacity .5
  .search-suggestion
    position relative
    display table
    width 100%
    line-height 48px
    .search-suggestion-icon
      display table-cell
      line-height 48px
      height 48px
      width 35px
      vertical-align middle
      text-align center
      padding-left 3px
      .cover
        display block
        max-height 40px
        max-width 32px
    .search-suggestion-text
      padding-left 3px
      padding-right 3px
      white-space normal
      margin-left 48px
      height 48px
      line-height 0px
      display table-cell
      vertical-align middle
      div
        line-height 24px
        max-height 48px
        display block