#reader-articles
  .reader-article-container
    height 100%
    position absolute
    background $bg
    z-index 150
    top 100%
    overflow-y auto
    transition top 0.5s ease-in-out

    &.active
      top 0

      .close-article
        top 16px

    .close-article
      position fixed
      top 100%
      right 32px
      fill rgba(0,0,0,0.54)
      cursor pointer
      transition top 0.5s ease-in-out

    .article
      margin 0 auto
      padding 16px
      max-height none

      h1, h2, h3
        word-break: break-word
        -webkit-hyphens auto
        -moz-hyphens auto
        -ms-hyphens auto
        hyphens auto

      p
        text-align justify

        &:empty
          margin 0

      figure
        margin 0 !important
        align-self center !important
        float none !important

        img
          display block
          margin 0 auto

        figcaption
          width 100% !important
          font-size 12px !important
          padding 8px 0
          text-align center