.alert-button
  color: white
  border: none
  background: none
  width: 24px !important
  padding: 8px 0 !important

.alert-subscription
  width: 500px
  min-width: 300px
  padding-left: 16px
  padding-right: 16px
  vertical-align: middle
  margin-top: 8px

.alert-add
  width: 500px
  min-width: 300px
  vertical-align: middle

.alert-notice
  width: 500px
  min-width: 300px
  vertical-align: middle
  text-align: justify
  margin-top: 24px
  color: var(--primary-color)
  font-size: 8pt

.alert-text-color
  color: white

.alert-verifying
  background: #86A3C1

.alert-verification-expired
  background: #EB3838

.alert-deleted-unsubscribed
  background: $gray

.alert-error-msg
  color: #EB3838

.alert-add-button[disabled]
  fill: $gray !important

.alert-icon svg
  fill: white

.alert-title
  font-size: 1.5em
  margin-bottom: 0.83em;

.alert-icon-title
  vertical-align: middle;

.new-alert:first-of-type {
  animation-name: new-alert;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

@keyframes new-alert {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.alert-count {
  font-size: 14px;
  cursor: pointer;
  width:120px
}


md-tooltip.md-origin-right {
  margin-left: 5px !important;
}

.alerts-container
  @media screen and (max-width:959px)
    flex-direction column
    justify-content flex-start
    align-items center


.alerts-info-container {
  padding:5%
  max-width:90%;
  width:800px;
}



